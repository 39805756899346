<template>
  <div class="container-fluid">
    <LoadingOverlay v-show="isLoading" />
    <CollectionNavTab />
    <div class="p-lg-2">
      <h2 class="roboto bold mb-5">{{ noEdit ? 'Detail' : collectionId ? 'Edit' : 'Add' }} Collection</h2>
      <!-- BRANDS -->
      <h2 class="subtitle roboto bold">Brand</h2>
      <hr />
      <!-- Select Brand -->
      <div class="row">
        <div class="col-md-2 pt-2">Brand <b class="text-danger">*</b></div>
        <div class="col-md-7">
          <InputSelect
            :options="brands"
            v-model.number="selectedBrandId"
            placeholder="Select Brand"
            :disabled="isProductLoading || collectionId || noEdit"
            :disabledFirstOpt="true"
            :classComponent="{'is-invalid-custom': $v.formData.brandId.$error}"
            :invalidMessage="[
              !$v.formData.brandId.isBrandValid ? 'Brand is required.' : null,
            ]"
            @change.native="onChangeBrand"
          />
          <div class="custom-text-info" v-if="isProductLoading">
            <font-awesome icon="spinner" spin />
            <span class="ml-2 text-nowrap">{{ `Loading all ${brands.find(el => el.id == formData.brandId).name} products` }} </span>
          </div>
        </div>
      </div>
      <!-- INFORMATION -->
      <h2 class="subtitle roboto bold mt-5">Information</h2>
      <hr />
      <!-- Collection Name -->
      <div class="row">
        <div class="col-md-2 pt-2">Name <b class="text-danger">*</b></div>
        <div class="col-md-7">
          <InputText
            :disabled="noEdit"
            type="text"
            v-model="$v.formData.name.$model"
            placeholder="Name"
            :classComponent="{'is-invalid-custom': $v.formData.name.$error}"
            :invalidMessage="[
              !$v.formData.name.required ? 'Name is required.' : null,
            ]"
          />
        </div>
      </div>
      <!-- Collection Description -->
      <div class="row mt-2">
        <div class="col-md-2 pt-2">Description <b class="text-danger">*</b></div>
        <div class="col-md-7">
          <InputText
            :disabled="noEdit"
            type="textarea"
            v-model="$v.formData.description.$model"
            placeholder="Description"
            :classComponent="{'is-invalid-custom': $v.formData.description.$error}"
            :invalidMessage="[
              !$v.formData.description.required ? 'Description is required.' : null,
            ]"
          />
        </div>
      </div>
      <!-- IMAGES -->
      <h2 class="subtitle roboto bold mt-5">Images</h2>
      <hr />
      <!-- Collection Desktop Image -->
      <!-- <div class="row">
        <div class="col-md-2 pt-2">Desktop Image <b class="text-danger">*</b></div>
        <div class="col-md-4">
          <b-form-file :disabled="noEdit" :state="$v.formData.desktopImage.$error ? false : null" ref="desktopImageFile" accept="image/*" @input="setPreviewImage($event, 'desktopImage', 1920, 404)"></b-form-file>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <small class="custom-text-info mt-1">Min Image dimensions : 1920x404 px</small>
            <div class="d-block invalid-feedback w-auto" v-if="$v.formData.desktopImage.$error">
              {{ !$v.formData.desktopImage.$required ? 'Desktop image is required.' : null }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" :class="{'d-none' : !desktopImageSrc}">
        <div class="col-md-6 offset-md-2">
          <vue-cropper
          ref="desktopImage"
          :src="desktopImageSrc"
          alt="Collection Desktop Image"
          :responsive="true"
          :viewMode="2"
          :aspect-ratio="1920 / 404"
          :zoomable="false"
          class="collection-img-cropper"
          @ready="setImage('desktopImage', 1920, 404)"
          @cropend="setImage('desktopImage', 1920, 404)"
          />
        </div>
      </div> -->

      <MyInputFormImageCropper
        id="image"
        v-model="$v.image.$model"
        label="Desktop Image"
        type="image"
        :width="1920"
        :height="404"
        :state="(!$v.image.$error || !$v.image.$invalid) ? null : false"
        :classComponent="{ 'is-invalid': $v.image.$error }"
        :invalidMessage="[
          !$v.image.required ? 'Desktop image is required.' : null,
        ]"
        :src="desktopImageSrc"
        :required="!$v.image.required ? true : null"
        :cropperCols="6"
      />
      <!-- Collection Mobile Image -->
      <!-- <div class="row mt-2">
        <div class="col-md-2 pt-2">Mobile Image <b class="text-danger">*</b></div>
        <div class="col-md-4">
          <b-form-file :disabled="noEdit" :state="$v.formData.mobileImage.$error ? false : null" ref="mobileImageFile" accept="image/*" @input="setPreviewImage($event, 'mobileImage', 1280, 615)" required></b-form-file>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <small class="custom-text-info mt-1">Min Image dimensions : 1280x615 px</small>
            <div class="d-block invalid-feedback w-auto" v-if="$v.formData.mobileImage.$error">
              {{ !$v.formData.mobileImage.$required ? 'Mobile image is required.' : null }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" :class="{'d-none' : !mobileImageSrc}">
        <div class="col-md-6 offset-md-2">
          <vue-cropper
            ref="mobileImage"
            :src="mobileImageSrc"
            alt="Collection Mobile Image"
            :responsive="true"
            :viewMode="2"
            :aspect-ratio="1280 / 615"
            :zoomable="false"
            class="collection-img-cropper"
            @ready="setImage('mobileImage', 1280, 615)"
            @cropend="setImage('mobileImage', 1280, 615)"
          />
        </div>
      </div> -->

      <MyInputFormImageCropper
        id="image-mobile"
        v-model="$v.image_mobile.$model"
        label="Mobile Image"
        type="image"
        :width="1280"
        :height="615"
        :state="(!$v.image_mobile.$error || !$v.image_mobile.$invalid) ? null : false"
        :classComponent="{ 'is-invalid': $v.image_mobile.$error }"
        :invalidMessage="[
          !$v.image_mobile.required ? 'Mobile image is required.' : null,
        ]"
        :src="mobileImageSrc"
        :required="!$v.image_mobile.required ? true : null"
        :cropperCols="6"
      />
      <!-- Collection Square Image -->
      <!-- <div class="row mt-2">
        <div class="col-md-2 pt-2">Square Image <b class="text-danger">*</b></div>
        <div class="col-md-4">
          <b-form-file :disabled="noEdit" :state="$v.formData.squareImage.$error ? false : null" ref="squareImageFile" accept="image/*" @input="setPreviewImage($event, 'squareImage', 336, 412)" required></b-form-file>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <small class="custom-text-info mt-1">Min Image dimensions : 336x412 px</small>
            <div class="d-block invalid-feedback w-auto" v-if="$v.formData.squareImage.$error">
              {{ !$v.formData.squareImage.$required ? 'Mobile image is required.' : null }}
            </div>
          </div>
        </div>
      </div>
      <div class="row" :class="{'d-none' : !squareImageSrc}">
        <div class="col-md-6 offset-md-2">
          <vue-cropper
            ref="squareImage"
            :src="squareImageSrc"
            alt="Collection Square Image"
            :responsive="true"
            :viewMode="2"
            :aspect-ratio="336 / 412"
            :zoomable="false"
            class="collection-img-cropper"
            @ready="setImage('squareImage', 336, 412)"
            @cropend="setImage('squareImage', 336, 412)"
          />
        </div>
      </div> -->

      <MyInputFormImageCropper
        id="image-square"
        v-model="$v.image_square.$model"
        label="Square Image"
        type="image"
        :width="336"
        :height="412"
        :state="(!$v.image_square.$error || !$v.image_square.$invalid) ? null : false"
        :classComponent="{ 'is-invalid': $v.image_square.$error }"
        :invalidMessage="[
          !$v.image_square.required ? 'Square image is required.' : null,
        ]"
        :src="squareImageSrc"
        :required="!$v.image_square.required ? true : null"
        :cropperCols="6"
      />
      <!-- PRODUCT LIST -->
      <h2 class="subtitle roboto bold mt-5">Product List</h2>
      <hr />
      <!-- Product List Tag -->
      <div class="row">
        <div class="col-md-2 pt-2">Product List <b class="text-danger">*</b></div>
        <div class="col-md-7">
          <div 
            class="product-tag-container"
            :class="{
              'product-list-invalid' : $v.formData.productListIds.$error,
              'disabled' : noEdit
            }"
          >
            <div class="tag" v-for="product in selectedProductList" :key="product.id">
              <span>{{ product.name }}</span><fawesome-pro variant="fal" icon="times" class="delete-product" @click.native="removeProduct(product)" />
            </div>
            <input type="text" v-model="filterProduct" placeholder="Type product name" />
            <div class="product-options">
              <div v-if="this.productList.data.length == 0 && formData.brandId == 0" class="no-hover">Please select the brand first</div>
              <div v-for="product in filteredProduct" :key="product.id" @click="addProduct(product)">
                {{ product.name }}
              </div>
              <div v-if="isProductLoading" class="text-center no-hover">
                Loading <font-awesome icon="spinner" spin />
              </div>
              <div v-if="filteredProduct.length == 0 && selectedProductList.length < this.productList.data.length && this.productList.data.length == this.productList.meta.total" class="no-hover">
                {{ `"${filterProduct}" does not exist or may have been added to the collection` }}
              </div>
            </div>
          </div>
          <div class="d-block invalid-feedback float-right w-auto" v-if="$v.formData.productListIds.$error">
            {{ !$v.formData.productListIds.$isProductListValid ? 'You must have at least 1 product.' : null }}
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <h2 class="subtitle roboto bold mt-5">Content</h2>
      <hr />
      <!-- Desktop Content -->
      <div class="row">
        <div class="col-md-2 pt-2">Desktop Content</div>
        <div class="col-md-7">
          <vue-ckedit :disabled="noEdit" v-model="formData.content" />
        </div>
      </div>
      <!-- Mobile Content -->
      <div class="row mt-2">
        <div class="col-md-2 pt-2">Mobile Content</div>
        <div class="col-md-7">
          <vue-ckedit :disabled="noEdit" v-model="formData.contentMobile" />
        </div>
      </div>
      <!-- CONTENT -->
      <h2 class="subtitle roboto bold mt-5">Background Image</h2>
      <hr />
      <!-- Desktop Content -->
      <MyInputFormImageCropper
        id="image-background"
        v-model="$v.image_background.$model"
        label="Mobile Image Background"
        type="image"
        :width="1080"
        :height="612"
        :state="(!$v.image_background.$error || !$v.image_background.$invalid) ? null : false"
        :classComponent="{ 'is-invalid': $v.image_background.$error }"
        :invalidMessage="[
          !$v.image_background.required ? 'Mobile image is required.' : null,
        ]"
        :src="backgroundImageSrc"
        :required="!$v.image_background.required ? true : null"
        :cropperCols="6"
      />
    </div>
    <div class="shadow my-5">&nbsp;</div>
    <div class="text-right" v-if="!noEdit">
      <b-button variant="info mr-2" @click="onSubmit">Save</b-button>
      <router-link to="/manage-collection" class="btn btn-secondary ml-2">Cancel</router-link>
    </div>
  </div>
</template>
<script>
const InputText = () => import("@/components/formCustom/InputText");
const InputSelect = () => import("@/components/formCustom/InputSelect");
const CollectionNavTab = () => import("@/components/collection/CollectionNavTab");
const LoadingOverlay = () => import("@/components/LoadingOverlay");
const MyInputFormImageCropper = () => import("@/components/form/MyInputFormImageCropper");

import { toaster } from "@/_helpers";
import { required, requiredIf } from "vuelidate/lib/validators";

const isBrandValid = (val) => val > 0 
const isProductListValid = (arr) => arr.length > 0 

const initialData = () => ({
  isLoading: false,
  isProductLoading: false,
  brands: [],

  productList: {
    meta: {},
    data: []
  },

  selectedProductList: [], // selected product list tag

  filterProduct: "", // keyword product name for filtering product options

  selectedBrandId : 0, // temp var for onChangeBrand confirmation box

  desktopImageSrc: "", 
  mobileImageSrc: "",
  backgroundImageSrc: "",
  squareImageSrc: "",

  formData: {
    brandId: 0, 
    name: "",
    description: "",
    content: "",
    contentMobile: "",
    productListIds: [],
    // desktopImage: "", 
    // mobileImage: "",
    // squareImage: "",
  },

  image: null,
  image_mobile: null,
  image_background: null,
  image_square: null,
});

export default {
  name: "SaveCollection",
  components: {
    InputText,
    InputSelect,
    CollectionNavTab,
    LoadingOverlay,
    MyInputFormImageCropper,
  },
  data() {
    return initialData();
  },
  validations() {
    return {
      formData: {
        brandId: { isBrandValid },
        name: { required },
        description: { required },
        // desktopImage : {
        //   required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
        // },
        // mobileImage : {
        //   required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
        // },
        // squareImage : {
        //   required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
        // },
        productListIds : { isProductListValid },
      },
      image : {
        required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
      },
      image_mobile : {
        required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
      },
      image_background : {
        required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
      },
      image_square : {
        required : requiredIf(() => this.$route.path.indexOf("/add") == 0)
      },
    };
  },
  computed: {
    collectionId() {
      return this.$route.params.id;
    },
    noEdit() {
      return this.$route.path.indexOf('/detail') == 0
    },
    filteredProduct: function() {
      return this.productList.data.filter(el => !this.selectedProductList.find(rel => rel.id == el.id) && (this.filterProduct ? new RegExp(this.filterProduct, "i").test(el.name) : el))
    },
  },
  mounted() {
    this.getBrands();
  },
  watch: {
    "formData.brandId"(val) {
      if (this.noEdit) return // prevent get product list by brand when view detail collection
      this.getProductListByBrand(val, 0)
    },
    $route(to) {
      const { path, params } = to
      const brandId = this.formData.brandId

      if (path.indexOf('/add') == 0) this.clearData(path)
      else if (path.indexOf('/detail') == 0) this.getCollectionDetail(params.id)
      else if (path.indexOf('/edit') == 0 && brandId > 0) this.getProductListByBrand(brandId, 0)
    }
  },
  methods: {
    async getBrands() {
      try {
        const res = await this.$api.cms.getBrands()
        if (res.status === 200) {
          this.brands = res.data.data.map(el => ({ id : el.brand.id, name : el.brand.name }))
          if (this.collectionId) this.getCollectionDetail(this.collectionId)
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async getProductListByBrand(brandId, page = 0) {
      try {
        this.isProductLoading = true
        const res = await this.$api.product.getProductsByBrand(50, page, null, [brandId], null)
        if (res.status === 200) {
          const mappedProduct = res.data.data.result.map(el => ({ id: el.id, name: el.name }))
          
          this.productList = {
            meta: res.data.meta,
            data: page == 0 ? [...mappedProduct] : [...this.productList?.data ?? [], ...mappedProduct], // if page == 0 (new brand id) => replace product list arr, else if page > 0 => deep copy product list arr 
          }

          this.isProductLoading = false
          if (this.productList.meta.limit * (this.productList.meta.offset + 1) <= this.productList.meta.total) this.getProductListByBrand(brandId, ++this.productList.meta.offset)
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCollectionDetail(collectionId) {
      try {
        this.isLoading = true
        const res = await this.$api.collection.getCollectionDetail(collectionId)
        if (res.status === 200) {
          // Set form data
          const d = res.data.data
          this.formData.name = d.name
          this.formData.brandId = this.selectedBrandId = d.brand.id
          this.formData.description = d.description
          this.formData.content = d.content
          this.formData.contentMobile = d.content_mobile
          this.formData.productListIds = d.product.map(el => el.id)

          // Set selected product tags
          this.selectedProductList = d.product.map(el => ({ id: el.id, name: el.name }))

          // set preview image
          this.desktopImageSrc = d.image_link
          this.mobileImageSrc = d.image_mobile_link
          this.backgroundImageSrc = d.image_background_link
          this.squareImageSrc = d.image_square_link
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false
    },
    async onSubmit() {
      this.isLoading = true
      try {
        this.$v.$touch();
        if (this.$v.$error) {
          toaster.make("Please fill in the form correctly", "danger");
          const errors = Object.keys(this.$v.formData)
            .map((el) => {
              if (!/^\$/.test(el))
                return {
                  form: el,
                  data: this.$v.formData[el],
                };
            })
            .filter((el) => el && el.data.$invalid);
          this.isLoading = false;
          return console.error("Invalid form data", errors);
        } 
        const fd = this.formData
        const data = {
          name: fd.name,
          description: fd.description,
          content: fd.content,
          content_mobile: fd.contentMobile,
          brand_id: fd.brandId,
          product_ids: fd.productListIds,
          // image: fd.desktopImage,
          // image_mobile: fd.mobileImage,
          // image_square: fd.squareImage 
        }

        const form = new FormData();
        form.append("data", JSON.stringify(data));
        if (this.image) form.append("image", this.image);
        if (this.image_mobile) form.append("image_mobile", this.image_mobile);
        if (this.image_background) form.append("image_background", this.image_background);
        if (this.image_square) form.append("image_square", this.image_square);
          
        const res = await (this.$route.path.indexOf("/add") == 0 ? this.$api.collection.saveCollection(form) : this.$api.collection.updateCollection(form, this.collectionId))
        if (res.status === 200) {
          toaster.make(res.data.message, "success")
          this.$router.push("/manage-collection")
        } else  toaster.make(res.data.message, "danger");
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.isLoading = false;
      }
    },
    setPreviewImage(e, type ,minWidth, minHeight) {
      try {
        const file = e
        if (file.type.indexOf('image/') === -1) return toaster.make('Please select an image file (jpg, jpeg, png)', "danger");
        if (file.type.indexOf('image/gif') === 0) return toaster.make('Image format cannot be gif', "danger");
        if (file.type.indexOf('image/svg') === 0) return toaster.make('Image format cannot be svg', "danger");

        if (typeof FileReader === 'function') {
          const img = new Image()
          let imgWidth, imgHeight
          let popToast = false
          img.src = window.URL.createObjectURL(file)
          img.onload = (e) => {
            if (e['path']) { // Chromium
              imgWidth = e['path'][0].naturalWidth;
              imgHeight = e['path'][0].naturalHeight;
            } else if (e['originalTarget']) { // Firefox
              imgWidth = e['originalTarget'].naturalWidth;
              imgHeight = e['originalTarget'].naturalHeight;
            } else {
              imgWidth = img.width;
              imgHeight = img.height;
            }

            if (imgWidth < minWidth) {
              toaster.make(`Image width must be at least ${minWidth} px`, "danger")
              popToast = true
            } 

            if (imgHeight < minHeight) {
              toaster.make(`Image height must be at least ${minHeight} px`, "danger")
              popToast = true
            } 

            if(popToast) return

            const reader = new FileReader();
            reader.onload = (event) => {
              this.$data[`${type}Src`] = event.target.result
              this.$refs[type].replace(event.target.result);
            };
            reader.readAsDataURL(file);
          } 
        }
      } catch (error) {
        console.error(error);
      }
    },
    setImage(type, minWidth, minHeight) {
      this.formData[type] = this.$data[`${type}Src`] =this.$refs[type].getCroppedCanvas({
        minWidth: minWidth,
        minHeight: minHeight,
      }).toDataURL();
    },
    addProduct(product) {
      this.selectedProductList.push(product)
      this.formData.productListIds.push(parseInt(product.id))
      this.filterProduct = ""
    },
    removeProduct(product) {
      this.selectedProductList.splice(this.selectedProductList.findIndex(el => el.id == product.id), 1)
      this.formData.productListIds.splice(this.formData.productListIds.findIndex(el => el == product.id), 1)
    },
    onChangeBrand(e) {
      // If selected product list exist => Show popup confirmation when change brand
      if (this.selectedProductList.length > 0) {
        this.$bvModal.msgBoxConfirm(
          "Are you sure want to change the current brand? if you change it, you will have to re-select the products you want for the collection.",
          {
            okTitle: "Yes",
            cancelTitle: "No",
            centered: true,
          }
        ).then((val) => {
          if(val) {
            this.formData.brandId = e.target.value
            // clear selected product tags & selected product ids for formData
            this.selectedProductList = []
            this.formData.productListIds = []
          } else this.selectedBrandId = this.formData.brandId
        }).catch(() => this.selectedBrandId = this.formData.brandId)
      } else this.formData.brandId = this.selectedBrandId
    },
    clearData(destination) {
      this.$router.replace({path: '/reload', query: {next: destination}})
    },
  },
};
</script>
<style scoped>
/* custom small text info for form */
.custom-text-info {
  color: grey;
  font-style: italic;
}
/* Product tag custom */
.product-tag-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.product-tag-container .tag {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: .25rem;
  background: #f2f2f2;
}
.tag .delete-product {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}
.product-tag-container input {
  flex-basis: 100%;
  padding: 5px;
  outline: none;
  border: 0;
}
/* Product tag custom validation */
.product-list-invalid {
  border-color: #dc3545;
}
.product-tag-container input:focus + .product-options {
  opacity: 1;
  visibility: visible;
}
.invalid-feedback + .product-tag-container {
  border-color: red;
}
/* Product tag no edit */
.product-tag-container.disabled :is(input, .delete-product) {
  display: none;
}
/* Product options */
.product-options {
  position: absolute;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  background: white;
  overflow-y: auto;
  visibility: collapse;
  width: 100%;
  max-height: 256px;
  opacity: 0;
  transition: 0s all;
  z-index: 9;
  margin: 0 -8px;
  top: 101%;
}
.product-options > div {
  padding: .5rem;
}
.product-options > div:not(.no-hover) {
  cursor: pointer;
}
.product-options > div:not(.no-hover):hover {
  background: #ced4da;
}
/* Custom image style for vue cropper */
.collection-img-cropper >>> img {
  border: 1px solid #ced4da;
}
</style>